import React, {Component} from 'react';
import Modal from "../../../common/modal/modal";
import Api from "../../../../services/api";
import Config from "../../../../config";
import {withStyles, Chip, Icon, Paper} from "@material-ui/core";
import _ from "lodash";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import {Image} from "react-bootstrap";
import moment from "moment";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    timeline: {
        position: "relative",
        margin: "0px auto",
        flex: 1,
        "&:before": {
            content: "''",
            position: "absolute",
            height: "100%",
            width: 2,
            left: "29%",
            backgroundColor: "#3333",
        },
        "& > ul > li": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            listStyleType: "none",
        }
    },
    point: {
        minWidth: 20,
        height: 20,
        backgroundColor: "#687799",
        zIndex: 2,
        position: "relative",
        left: 7,
        borderRadius: "5%",
        textAlign: "center",
        color: "#FFF",
        fontWeight: 600
    },
    leftContent: {
        width: "24%"
    },
    rightContent: {
        display: "flex",
        width: "70%",
        paddingLeft: 25,
        alignItems: "center"
    },
    contentLeft: {
        display: "flex",
        alignItems: "center"
    },
    chip: {
        flex: 4,
        "& > .MuiChip-label": {
            fontSize: "1.2rem",
            color: "#FFF"
        },
        "& > .MuiChip-icon": {
            display: "flex",
            justifyContent: "center",
            color: "#FFF"
        }
    },
    line: {
        flex: 1,
        height: 1,
        backgroundColor: "#3333"
    },
    rowLine: {
        marginBottom: 15,
        paddingBottom: 15,
        borderBottom: "0.5px dashed #3333"
    },
    chipSuccess: {
        backgroundColor: theme.palette.success.main
    },
    chipWarning: {
        backgroundColor: theme.palette.warning.main
    },
    chipDanger: {
        backgroundColor: theme.palette.danger.main
    },
    chipInfo: {
        backgroundColor: theme.palette.info.main
    },
    info: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 10,
    },
    dateInfo: {
        display: "flex",
    },
    overlay: {
        zIndex: 999,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        backgroundColor: "#FFFFFF88"
    }
});

class W75F8002 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataHistory: {total: 0, rows: []},
            isLoading: false,
        }
        this.filter = {
            skip: 0,
            limit: 11,
        }
    }

    onLoadMore = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.filter.skip = this.filter.limit + 11;
            this.loadHistory();
        }
    }

    loadHistory = async () => {
        const {transID} = this.props;
        const {dataHistory} = this.state;
        this.setState({isLoading: true})
        const res = await Api.put('/w75f8000/load-history', {
            TransID: transID,
            Language: Config.language || '84',
            ...this.filter
        });
        _.delay(() => this.setState({isLoading: false}), 1000)
        const resData = _.get(res, "data", null);
        const resMessageErr = _.get(res, "message", _.get(resData, "Message", ""));
        const rows = [...dataHistory.rows, ..._.get(resData, "rows", [])];
        const total = _.get(resData, "total", 0);
        if (resMessageErr) {
            Config.popup.show("ERROR", resMessageErr);
            return false;
        }
        if (resData) {
            this.setState({dataHistory: {rows, total}});
            return true;
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.transID !== this.props.transID) {
            this.setState({dataHistory: {total: 0, rows: []}}, () => this.loadHistory())
        }
    }

    renderHistoryTimeline = () => {
        const {classes} = this.props;
        const {dataHistory} = this.state;
        return _.map(_.get(dataHistory, "rows", []), (item, i) => {
            const voucherDate = _.get(item, "VoucherDate", "");
            const date = moment(voucherDate).isValid() ? moment.utc(voucherDate).format("DD/MM/YYYY kk:mm:ss") : "";
            let chip = {
                iconName: "fal fa-ellipsis-h",
                classesName: classes.chipInfo
            };
            switch (_.get(item, "IsStatus", null)) {
                case 0:
                    chip = {
                        iconName: "far fa-file-plus",
                        classesName: ""
                    };
                    break;
                case 2:
                    chip = {
                        iconName: "fas fa-check",
                        classesName: classes.chipSuccess
                    };
                    break;
                case 4:
                    chip = {
                        iconName: "fas fa-times",
                        classesName: classes.chipDanger
                    };
                    break;
                case 5:
                    chip = {
                        iconName: "fas fa-do-not-enter",
                        classesName: classes.chipWarning
                    };
                    break;
                default:
                    break;
            }
            return (
                <li key={i} className={classes.rowLine}>
                    <div className={classes.leftContent}>
                        <div className={classes.contentLeft}>
                            <Chip
                                className={_.join([classes.chip, chip.classesName], " ")}
                                icon={<Icon fontSize="small" className={chip.iconName}/>}
                                label={_.get(item, "StatusName", "")}
                            />
                            <div className={classes.line}/>
                        </div>
                    </div>
                    <Paper elevation={3} className={classes.point}>{_.get(item, "ApprovalLevel", "")}</Paper>
                    <div className={classes.rightContent}>
                        <UserImage data={item}/>
                        <div className={classes.info}>
                            <UserName style={{fontWeight: 600, fontSize: "1.2rem"}} data={item}/>
                            <div className={classes.dateInfo}>
                                <Image src={require("../../../../assets/images/icon-calendar.svg")}/>
                                <div style={{paddingLeft: "8px", fontSize: "1.1rem", lineHeight: 1.2}}
                                     className={"date-text-info"}>
                                    {date}
                                </div>
                            </div>
                            <span style={{fontWeight: 400, fontSize: "1.1rem"}}>{_.get(item, "NotesU", "")}</span>
                        </div>
                    </div>
                </li>
            )
        })
    }

    render() {
        const {classes, open, onClose} = this.props;
        const {dataHistory, isLoading} = this.state;
        return (
            <Modal open={open}
                   maxWidth={"md"}
                   fullWidth={true}
                   onClose={() => {
                       this.filter.skip = 0;
                       this.filter.limit = 11;
                       onClose('showW75F8002')
                   }}
                   title={Config.lang("DHR_Lich_su_duyet")}
            >

                <Modal.Content onScroll={this.onLoadMore}>
                    {isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color="primary"/>
                        </div>
                        : ""}
                    <div className={classes.timeline}>
                        <ul>
                            {this.renderHistoryTimeline()}
                        </ul>
                    </div>
                </Modal.Content>
                {dataHistory.total > 11 ?
                    <div className={"MuiDialogActions-root"} style={{display: "flex", justifyContent: "center"}}>
                        <KeyboardArrowDownIcon className={"MuiSvgIcon-colorAction"}/>
                    </div>
                    :
                    ""}
            </Modal>
        );
    }

}

export default withStyles(styles, {withTheme: true})(W75F8002)