/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 8/21/2020
 * @Example
 */
import React, {PureComponent} from 'react';
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import {Column, Summary, TotalItem} from "devextreme-react/data-grid";
import {Checkbox as CheckboxM} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import NumberFormat from 'react-number-format';

const styles = {
    checkbox: {
        padding: '0 4px'
    },
    editorGrid: {
        "&:after": {
            content: "none"
        },
        "& input": {
            height: "40px !important",
            minHeight: "40px !important",
        }
    },
};

class W77F1013TableRecCost extends PureComponent {

    renderDisabled = (e) => {
        const {classes} = this.props;
        return (
            <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"/>
        );
    };

    formatNumber = (e) => {
        if(!e) return false;
        return <NumberFormat value={e.value} displayType={'text'} thousandSeparator={true} />
    };

    render() {
        const { classes, getCboCostTypes, dataCostWorkPlansZero} = this.props;
        return (
            <div className={"mgb25"} style={{width: '100%'}}>
                <GridContainer
                    columnAutoWidth={true}
                    dataSource={dataCostWorkPlansZero}
                    style={{width: "100%"}}
                    hoverStateEnabled={true}
                    allowColumnResizing={true}
                    typeShort={true}
                >
                    <Column  caption={Config.lang("DHR_Khoan_chi")}
                             dataField={'RecCostID'}
                             width={250}
                             lookup={{
                                 dataSource: getCboCostTypes,
                                 valueExpr: "RecCostID",
                                 displayExpr: "RecCostName",
                             }}
                             editorOptions={{ placeholder: Config.lang("DHR_Khoan_chi") + "...",
                             }}

                    >
                    </Column>
                    <Column
                        dataField="AmountVND"
                        caption={Config.lang("DHR_Thanh_tien(VND)")}
                        dataType="number"
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        width={250}
                        cellRender={this.formatNumber}
                    />
                    <Column
                        caption={Config.lang("DHR_Thanh_tien(USD)")}
                        dataField={'AmountUSD'}
                        dataType="number"
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        width={250}
                    />


                    <Column
                        caption={Config.lang("DHR_Cong_ty_thanh_toan")}
                        dataField={'IsCompanyPay'}
                        width={150}
                        alignment={'center'}
                        cellRender={this.renderDisabled}
                        editCellRender={(e) => {
                            return <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"
                                              onChange={(chk) => {
                                                  e.component.cellValue(e.rowIndex, "IsCompanyPay", chk.target.checked ? 1 : 0);
                                              }}
                            />
                        }}
                    />
                    <Column
                        caption={Config.lang("DHR_Ghi_chu")}
                        dataField={'Note'}
                    />
                    <Summary recalculateWhileEditing={true}>
                        <TotalItem
                            column="RecCostID"
                            displayFormat={Config.lang("DHR_Tong")}
                        />
                        <TotalItem
                            column="AmountVND"
                            summaryType="sum"
                            valueFormat={"fixedPoint"}
                            displayFormat={'{0}'}
                        />
                        <TotalItem
                            column="AmountUSD"
                            summaryType="sum"
                            displayFormat={'{0}'}
                        />
                        <TotalItem
                            column="ForeignCurrency"
                            summaryType="sum"
                            displayFormat={'{0}'}
                        />
                    </Summary>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(W77F1013TableRecCost) ;