import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import LabelText from "../../../common/label-text/label-text";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import Approvals from "../../../approvals/approvals";

import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from "lodash";
import AnimateHeight from "react-animate-height";
import GridContainer from "../../../grid-container/grid-container";
import {Column, Summary, TotalItem} from "devextreme-react/data-grid";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
    },
    divText: {
        fontWeight: 500,
    },
    gridTable: {
        "& .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort, .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator, .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content": {
            color: "#B2BABB !important",
        },
        "& .dx-datagrid-content": {
            maxHeight: "calc(30px * 5)",
            overflow: "scroll"
        }
    },
};

class W77F1012 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataForm: {},
            VoucherID: "",
            toggleTable1: true,
            toggleTable2: true,
        };
        this.defaultParams = {
            FormID: "W77F1012",
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W77F1012", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadFormInfo = (VoucherID) => {
        const params = {
            ...this.defaultParams,
            VoucherID,
        };
        this.setState({loading: true});
        this.props.approvalActions.getFormInfo(params, (err, data) => {
            this.setState({loading: false});
            if (err) return Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
            if (data) {
                this.setState({dataForm: data});
            }
        });
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(_.get(e, "VoucherID", ""));
    };

    formatDate = (date, typeFormat = "DD/MM/YYYY") => {
        if (!date) return "";
        if (moment(date).isValid()) {
            return moment.utc(date).format(typeFormat);
        }
        return "";
    };

    formatCurrency = (value, currency = "USD") => {
        if (!value) return "";
        let valueParsed = parseFloat(value);
        let languageCode = "en-US";
        let regexSeparator = /\,/g; //eslint-disable-line

        let charReplace = ".";
        let options = valueParsed % 1 === 0 ? {minimumFractionDigits: 0, maximumFractionDigits: 0} : {};
        if (currency === "VND") {
            languageCode = "vi-VN";
            regexSeparator = /\./g; //eslint-disable-line
            charReplace = ",";
            valueParsed = value;
        }

        const formatter = new Intl.NumberFormat(languageCode, {
            style: "currency",
            currency,
            ...options,
        });

        return formatter.format(valueParsed).replace(regexSeparator, charReplace);
    };

    renderFormInfo = (data) => {
        const {classes} = this.props;
        const {toggleTable1, toggleTable2} = this.state;
        const userData = Config.getUser({EmployeeID: _.get(data, "EmployeeID", "")});
        const useCurency = _.get(data, "UseCurency[0].UseCurency", 2);
        const objDataField = {
            0: [
                {
                    label: Config.lang("DHR_Ma_cong_tac"),
                    value: _.get(data, "BusinessTravelCode", ""),
                },
                {
                    label: Config.lang("DHR_Ngay_di_/_ve"),
                    value: _.join(
                        _.compact([this.formatDate(_.get(data, "FromDate", "")), this.formatDate(_.get(data, "ToDate", ""))]),
                        " - ",
                    ),
                },
            ],
            1: [
                {
                    label: Config.lang("DHR_Dia_diem"),
                    value: _.join(_.compact([_.get(data, "IsDomesticName", ""), _.get(data, "BusinessLocation", "")]), " - "),
                },
            ],
            2: [
                {
                    label: Config.lang("DHR_Muc_dich_cong_tac"),
                    value: _.get(data, "Content", ""),
                },
            ],
            3: [
                {
                    label: Config.lang("DHR_So_tien_de_xuat_tam_ung"),
                    value: _.join(
                        _.compact([
                            this.formatCurrency(_.get(data, "ProAmountVND", ""), "VND"),
                            useCurency > 1 ? this.formatCurrency(_.get(data, "ProAmountUSD", ""), "USD") : false,
                        ]),
                        " ~ ",
                    ),
                },
                {
                    label: Config.lang("DHR_Ngay_de_xuat_tam_ung"),
                    value: this.formatDate(_.get(data, "ProDateAdvance", "")),
                },
            ],
            4: [
                {
                    label: Config.lang("DHR_Ly_do_tam_ung"),
                    value: _.get(data, "Reason", ""),
                },
            ],
        };
        const dataTable1 = _.get(data, "RecCost", []);
        const dataTable2 = _.get(data, "DetailContent", []);
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <LabelText
                                className={classes.labelEmployee}
                                fullWidth={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                allowPadding={true}
                                value={() => (
                                    <div className={"display_row align-center pdb5"}
                                         style={{width: "100%", height: 37}}>
                                        <div className={"display_row align-center pdb5"}
                                             style={{width: "100%", height: 37}}>
                                            <UserImage data={data || userData} width={32} height={32}/>
                                            <UserName data={data || userData} allowHover={false}/>
                                        </div>
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                {_.map(_.keysIn(objDataField), (key) => (
                    <Row key={key}>
                        {_.map(objDataField[key], (item, i) => (
                            <Col key={i} md={12 / objDataField[key].length} xs={12} className={"mgb15"}>
                                <LabelText InputProps={{readOnly: true}} label={item.label} value={item.value}
                                           fullWidth={true}/>
                            </Col>
                        ))}
                    </Row>
                ))}
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <label
                                className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    this.setState({toggleTable1: !toggleTable1});
                                }}
                            >
                                <i
                                    style={{
                                        transform: toggleTable1 ? "rotate(0)" : "rotate(180deg)",
                                        transition: "0.4s"
                                    }}
                                    className={"fas fa-caret-down mgr5"}
                                />
                                {Config.lang("DHR_Khoan_chi_phi_du_kien")}
                            </label>
                            <AnimateHeight
                                duration={500}
                                height={toggleTable1 ? "auto" : 0} // see props documentation below
                            >
                                <GridContainer
                                    loading={false}
                                    gridProps={{
                                        className: `grid_luc ${classes.gridTable}`,
                                    }}
                                    style={{border: "none"}}
                                    dataSource={dataTable1}
                                    showRowLines={false}
                                    showBorders={false}
                                    noDataText={Config.lang("DHR_No_data")}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                >
                                    <Column caption={Config.lang("DHR_Khoan_chi")} dataField={"RecCostName"}/>
                                    <Column
                                        width={180}
                                        alignment="center"
                                        caption={Config.lang("DHR_Thanh_tien(VND)")}
                                        dataField={"AmountVND"}
                                        cellRender={e => this.formatCurrency(_.get(e, "row.data.AmountVND", ""), "VND")}
                                    />
                                    {useCurency === 2 ? (
                                        <Column
                                            width={180}
                                            alignment="center"
                                            caption={Config.lang("DHR_Thanh_tien(USD)")}
                                            dataField={"AmountUSD"}
                                            cellRender={e => this.formatCurrency(_.get(e, "row.data.AmountUSD", ""), "USD")}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <Column
                                        width={220}
                                        caption={Config.lang("DHR_Cong_ty_thanh_toan")}
                                        dataField={"IsCompanyPay"}
                                        alignment={"center"}
                                        dataType={"boolean"}
                                    />
                                    <Column dataField={"Note"} width={160} caption={Config.lang("DHR_Ghi_chu")}/>
                                    <Summary>
                                        <TotalItem column="RecCostName"
                                                   customizeText={() => Config.lang("DHR_Tong_cong") + ": "}/>
                                        <TotalItem
                                            column="AmountVND"
                                            summaryType="sum"
                                            customizeText={(data) => this.formatCurrency(_.get(data, "value", ""), "VND")}
                                        />
                                        {useCurency === 2 ? (
                                            <TotalItem
                                                column="AmountUSD"
                                                summaryType="sum"
                                                customizeText={(data) => this.formatCurrency(_.get(data, "value", ""), "USD")}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Summary>
                                </GridContainer>
                            </AnimateHeight>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <label
                                className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    this.setState({toggleTable2: !toggleTable2});
                                }}
                            >
                                <i
                                    style={{
                                        transform: toggleTable2 ? "rotate(0)" : "rotate(180deg)",
                                        transition: "0.4s"
                                    }}
                                    className={"fas fa-caret-down mgr5"}
                                />
                                {Config.lang("DHR_Nhat_ky_cong_tac")}
                            </label>
                            <AnimateHeight
                                duration={500}
                                height={toggleTable2 ? "auto" : 0} // see props documentation below
                            >
                                <GridContainer
                                    loading={false}
                                    gridProps={{
                                        className: `grid_luc ${classes.gridTable}`,
                                    }}
                                    style={{border: "none"}}
                                    dataSource={dataTable2}
                                    showRowLines={false}
                                    showBorders={false}
                                    noDataText={Config.lang("DHR_No_data")}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                >
                                    <Column width={160} caption={Config.lang("DHR_Noi_dung")} dataField={"Content"}/>
                                    <Column
                                        alignment="center"
                                        width={180}
                                        dataType="date"
                                        caption={Config.lang("DHR_Ngay_khoi_hanh")}
                                        dataField="FromDate"
                                    />
                                    <Column
                                        alignment="center"
                                        width={120}
                                        dataType="date"
                                        caption={Config.lang("DHR_Ngay_den")}
                                        dataField="ToDate"
                                    />
                                    <Column alignment="center" caption={Config.lang("DHR_Diem_di")}
                                            dataField={"Departure"}/>
                                    <Column alignment="center" caption={Config.lang("DHR_Diem_den")}
                                            dataField={"Destination"}/>
                                </GridContainer>
                            </AnimateHeight>
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    };

    render() {
        const {loading, dataForm, VoucherID, iPermission} = this.state;
        if (!iPermission) return null;
        return (
            <>
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Duyet_cong_tac")}/>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Approvals
                                parentProps={this.props}
                                FormID={"W77F1012"}
                                disabled={loading}
                                ref={"Approvals"}
                                dataForm={dataForm}
                                selectedRowKey={VoucherID}
                                disabledApproval={iPermission < 2}
                                singleClick
                                formInfoRender={(data) => this.renderFormInfo(data)}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
    }
}

export default compose(
    connect(
        (state) => ({
            getGridApproval: state.approvals.getGridApproval,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W77F1012);
