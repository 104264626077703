/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 20/08/2021
 * @Example Popup lịch sử duyệt động
 */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Loading} from "../../../common/loading/loading";
import moment from "moment";
import _ from "lodash";
import {
    Modal, ModalBody, ModalHeader, Typography, Avatar
} from "diginet-core-ui/components";
import {makeStyles, useTheme} from "@material-ui/core";
import * as W84F3010Actions from "../../../../redux/W8X/W84F3010/W84F3010_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import {PaperFilled, HourGlass, Approval, Close, Delete, CalendarNew} from "diginet-core-ui/icons";

const useStyles=makeStyles(theme => ({
    Modal:{
        maxHeight:'90%',
        width:'fit-content'
    },
    ModalBody: {
        padding: 0,
        overflow: 'auto',
        width:'fit-content',
        maxWidth: '100%'
    },
    Content:{
        display: 'flex',
        margin:0,
        minHeight: 100,
        width: 570,
        maxWidth: '100%'
    },
    Voucher: {
        border: '1px solid #D4D5D9',
        borderWidth: '0 1px 1px 0',
        width: 570,
        maxWidth: '100%'
    },
    ListApprovals:{
        position: 'relative',
        '&>div:before': {
            content: '""',
            height: 'calc(100% - 48px)',
            width: 4,
            position: "absolute",
            top: 24,
            left: 202,
            backgroundColor: '#E2E3EB',
            borderRadius: 4,
        }
    },
    Approvals: {
        '& .status-view': {
            width: 144,
            maxWidth: '100%',
            minHeight: 32,
            borderRadius: 16,
            display: 'flex',
            gap: 4
        },
        '& .approval-status': {
            borderRadius: 4,
            width: 24,
            height: 24,
            display: 'flex',
            zIndex: 2
        },
        '& hr': {
            width: 24,
            height: 1
        }
    },
    ApprovalsItem:{
        flexWrap: 'wrap',
    },
    Avatar: {
        width: 48,
        height: 48,
        borderRadius: 48,
        margin: '0 16px 16px 0',
        '&:hover': {
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        }
    },
    "@media only screen and (max-width: 580px)": {
        Approvals:{
            flexDirection:'column',
            paddingTop:10,
            position:'relative',
            '&>div:first-child':{
                position:'absolute',
                top:-14,
                width:'100%'
            },
            '&>div:last-child':{
                paddingTop:20
            },
            '& hr':{
                margin:0
            }
        },
        ApprovalsItem:{
            borderRadius: 4,
            padding:10,
            border: props => `1px solid ${props.color || theme.palette.disabled.main}`
        },
        ListApprovals:{
            position:'inherit',
            '&>div:before': {
                display:'none'
            }
        },
        Content:{
            flexDirection: 'column'
        }
    },
    "@media only screen and (min-width: 1250px)": {
        Content:{
            width: 1140
        }
    },
    "@media only screen and (min-width: 1920px)": {
        Content: {
            width: 1710
        }
    },
}));

const W84F3010=(props) => {
    const {FormID, TransID, open, onClose} = props;
    const [data, setData] = useState(null);
    const classes=useStyles();

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Function load data when init popup
     * {no params}
     * */
    const loadData=() => {
        const params={
            VoucherID: TransID,
            FormID
        };
        props.w84f3010Actions.getData(params, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            setData(data || []);
        });
    };

    return (
        <>
            <Modal className={classes.Modal}
                   open={open}
                   onClose={onClose}>
                <ModalHeader>{Config.lang('Lich_su_duyet')}</ModalHeader>
                <ModalBody className={`display_row flex-wrap ${classes.ModalBody}`}>
                    {!data && <Loading/>}
                    {data && !data.length &&
                        <Typography style={{textAlign:'center'}}>{Config.lang("No_data")}</Typography>
                    }
                    <div className={classes.Content}>
                        {data?.map((item, idx) => <Voucher data={item} key={idx}/>)}
                    </div>
                </ModalBody>
            </Modal>

        </>
    );
};

const Voucher=(props) => {
    const {data}=props;
    const classes=useStyles();

    // group and sort data by ApprovalLevel
    const dataApproval=_(data?.Approval)
    .sortBy(item => item.ApprovalLevel)
    .groupBy(item => item.ApprovalLevel)
    .value();
    return (
        <div className={classes.Voucher}>
            <Typography type={'h3'}
                        style={{
                            minHeight: 48,
                            padding: '14px 24px',
                            borderBottom: "1px solid #D4D5D9"
                        }}
            >
                {data?.VoucherName}
            </Typography>
            <div style={{padding: 24}} className={classes.ListApprovals}>
                {Object.keys(dataApproval).map((key, idx) => {
                    return (
                        <>
                            {idx !== 0 && <hr style={{borderTop: '1px dashed #D4D5D9'}}/>}
                            <Approvals data={dataApproval} ApprovalLevel={key} key={idx}/>
                        </>
                    )
                })}
            </div>
        </div>
    )
};

const Approvals = (props) => {
    const {data, ApprovalLevel} = props;
    const theme = useTheme();

    const ApprovalStatus = data[ApprovalLevel][0].ApprovalStatus;

    // list icon approved
    const icon={
        0: {
            icon: PaperFilled,
            color: theme.palette.disabled.main
        },
        1: {
            icon: HourGlass,
            color: theme.palette.info.main
        },
        2: {
            icon: Approval,
            color: theme.palette.success.main
        },
        4: {
            icon: Close,
            color: theme.palette.danger.main
        },
        5: {
            icon: Delete,
            color: theme.palette.warning.main
        }
    };
    const dataApproval=data[ApprovalLevel];

    const Icon=icon[ApprovalStatus]?.icon || Approval;
    const color=icon[ApprovalStatus]?.color || Config.coreTheme?.colors?.success;

    const classes = useStyles({color});

    return (
        <div className={`display_row ${classes.Approvals}`}>
            <div style={{height: 48, marginRight: 24}} className={"display_row align-center valign-middle"}><Typography
                type={"h3"}
                className={"align-center align-center valign-middle status-view"}
                style={{backgroundColor: color}}
                color={Config.coreTheme?.colors?.white}
            >
                <Icon color={"white"}/>
                {dataApproval[0].ApprovalStatusName}
            </Typography>
                <hr style={{backgroundColor: color}}/>
                <Typography type={"h3"}
                            className={"approval-status align-center align-center valign-middle"}
                            color={Config.coreTheme?.colors?.white}
                            style={{backgroundColor: color}}
                >
                    {ApprovalLevel}
                </Typography>
            </div>
            <div style={{display: dataApproval.length === 1 ? 'block' : 'flex'}}
                 className={classes.ApprovalsItem}
            >
                {dataApproval.map((item, idx) => <UserInfo length={dataApproval.length} data={item} key={idx}/>)}
            </div>
        </div>
    )
};

const UserInfo=(props) => {
    const { data, length }=props;
    const classes=useStyles();
    const user=Config.getUser({UserID: data?.ApproverID});
    return (
        <div className={"display_row align-left"}>
            <Avatar src={user?.UserPictureURL}
                    data={()=>(
                        <Typography type={"p3"}
                                    color={"white"}>
                            {user?.UserName}
                        </Typography>
                    )}
                    className={classes.Avatar}
                    hoverAble={length > 1}
                    direction={"down"}
                    readOnly
            />
            {length === 1 &&
            <div className={"display_col"} style={{gap: 4}}>
                <Typography type={'h3'}>
                    {data.AprroverName}
                </Typography>
                <Typography type={"p2"}
                            className={"align-center"}
                            style={{display: 'flex', gap: 5}}>
                    <CalendarNew color={"primary"}
                                 width={15}
                                 height={15}/>
                    {data.VoucherDate && moment(data.VoucherDate).isValid() ? Config.convertDate(data.VoucherDate,'', 'DD/MM/YYYY HH:mm:ss') : ''}
                </Typography>
                <Typography type={'p2'}>
                    {data.Notes}
                </Typography>
            </div>
            }
        </div>
    )
};

export default compose(
    connect(null,
        (dispatch) => ({
            w84f3010Actions: bindActionCreators(W84F3010Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W84F3010);
