import React, {Component} from 'react';
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import {Column} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import UndoIcon from '@material-ui/icons/Undo';
import IconButton from "@material-ui/core/IconButton";
import Icons from "../../../common/icons";

class W77F1011TableContent extends Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;
    }

    renderButtons = (e) => {
        return (
           <div className={"grid-action-hover hidden"}>
               {
                   !e.row.isEditing ?
                       <IconButton
                           aria-label="view"
                           style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                           onClick={() => e.component.deleteRow(e.rowIndex)}
                       >
                           <Icons name={"delete"} />
                       </IconButton>
                       :
                       <>
                           <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                       onClick={() => e.component.saveEditData()}
                           >
                               <Icons name={"save_filled"} type={"inline"}/>
                           </IconButton>
                           <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                       onClick={() => e.component.cancelEditData()}
                           >
                               <UndoIcon fontSize="small"/>
                           </IconButton>
                       </>
               }
           </div>
        );
    };

    render() {
        const {gridRef, dataWorkingDiary} = this.props;
        return (
            <div className={"mgb25"} style={{width:'100%'}}>
                <GridContainer
                    reference={ref => {
                        gridRef && gridRef(ref);
                        this.dataGrid = ref;
                    }}
                    columnAutoWidth={true}
                    dataSource={dataWorkingDiary}
                    editing={{
                        mode: 'cell',
                        refreshMode: "reshape",
                        // allowAdding: true,
                        allowUpdating: true,
                        texts: {
                            confirmDeleteMessage: ""
                        },
                    }}
                    // filterRow={{
                    //     visible: true,
                    //     showOperationChooser: false,
                    // }}
                    hoverStateEnabled={true}
                    typeShort={true}
                    allowColumnResizing={true}
                >
                    <Column
                        caption={Config.lang("DHR_Hanh_dong")}
                        width={110}
                        alignment={"center"}
                        visible={Config.isMobile}
                        allowEditing={false}
                        cellRender={this.renderButtons}
                    />
                    <Column  caption={Config.lang("DHR_Noi_dung_nhat_ky")}
                             dataField={'Content'}
                    />
                    <Column
                        caption={Config.lang("DHR_Ngay_khoi_hanh")}
                        dataField={'FromDate'}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                        alignment={'center'}
                        editorOptions={{
                            placeholder: "DD/MM/YYYY"
                        }}
                        width={200}
                    />
                    <Column
                        caption={Config.lang("DHR_Ngay_den")}
                        dataField={'ToDate'}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                        alignment={'center'}
                        editorOptions={{
                            placeholder: "DD/MM/YYYY"
                        }}
                        width={200}
                    />
                    <Column
                        caption={Config.lang("DHR_Diem_di")}
                        dataField={'Departure'}
                        width={250}
                    />
                    <Column
                        caption={Config.lang("DHR_Diem_den")}
                        dataField={'Destination'}
                        width={250}
                    />
                    <Column
                            visible={!Config.isMobile}
                            allowEditing={false}
                            fixed={true}
                            fixedPosition={"right"}
                            cellRender={this.renderButtons}
                    />
                </GridContainer>
            </div>
        );
    }
}
W77F1011TableContent.propsTypes = {
    gridRef: PropTypes.func,
    dataWorkingDiary: PropTypes.any
};


export default W77F1011TableContent;