/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 8/21/2020
 * @Example
 */
import React, {PureComponent} from 'react';
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import {Column, RequiredRule, Summary, TotalItem} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import UndoIcon from '@material-ui/icons/Undo';
import IconButton from "@material-ui/core/IconButton";
import {Checkbox as CheckboxM} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Icons from "../../../common/icons";
import NumberFormat from 'react-number-format';

const styles = {
    checkbox: {
        padding: '0 4px'
    },
    editorGrid: {
        "&:after": {
            content: "none"
        },
        "& input": {
            height: "40px !important",
            minHeight: "40px !important",
        }
    },
};

class W77F1013TableRecCost extends PureComponent {
    constructor(props) {
        super(props);
        this.dataGrid = null;
        this.addGrid = false;
        // this.keyInserting = "";
    }

    renderButtons = (e) => {
        return (
          <div className={"grid-action-hover hidden"}>
              {
                  !e.row.isEditing ?
                      <IconButton
                          aria-label="view"
                          style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                          onClick={() => e.component.deleteRow(e.rowIndex)}
                      >
                          <Icons name={"delete"} />
                      </IconButton>
                      :
                      <>
                          <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                      onClick={() => e.component.saveEditData()}
                          >
                              <Icons name={"save_filled"} type={"inline"}/>
                          </IconButton>
                          <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                      onClick={() => e.component.cancelEditData()}
                          >
                              <UndoIcon fontSize="small"/>
                          </IconButton>
                      </>
              }
          </div>
        );
    };

    renderIsCompanyPay = (e) => {
        const {classes} = this.props;
        return (
            <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"
                // onChange={(e) => {
                //     data.IsCompanyPay = e.target && e.target.checked ? 1 : 0;
                // }}
            />
        );
    };

    onConvertibleValueCurrentcies = (e) => {
        if (!e) return false;
        const {getConvertibleValueCurrentcies} = this.props;
        const data = e.newData ? e.newData : null;
        const oldData = e.oldData ? e.oldData : null;
        const rowIndex = this.addGrid ? 0 : e.component && e.component.getRowIndexByKey(e.key);
        let key = this.fieldUpdating || "";
        key = key === "CurrencyName" ? "CurrencyID" : key;
        const fieldValid = ["AmountVND", "AmountUSD", "ForeignCurrency", "CurrencyID"];
        if (key && fieldValid.indexOf(key) > -1 && getConvertibleValueCurrentcies) {
            let params = {};
            switch (key) {
                case "AmountVND":
                    params = {
                        Mode: 0,
                        Amount:  data.AmountVND,
                        CurrencyID: ""
                    };
                    this.getApi(key, params, rowIndex);
                    break;
                case "AmountUSD":
                    params = {
                        Mode: 1,
                        Amount: data.AmountUSD,
                        CurrencyID: ""
                    };
                    this.getApi(key, params, rowIndex);
                    break;
                case "CurrencyID":
                    let Amount = "";
                    if (this.addGrid && this.dataGrid) {
                        Amount = this.dataGrid.instance.cellValue(0, "ForeignCurrency");
                    } else if (oldData) {
                        Amount = oldData.ForeignCurrency ?oldData.ForeignCurrency : ""
                    }
                    params = {
                        Mode: 2,
                        Amount:  Amount,
                        CurrencyID: data.CurrencyID
                    };
                    if(params && params.Amount && params.CurrencyID) {
                        this.getApi(key, params, rowIndex);
                    }
                    break;
                case "ForeignCurrency":
                    let CurrencyID = "";
                    if (this.addGrid && this.dataGrid) {
                        CurrencyID = this.dataGrid.instance.cellValue(0, "CurrencyID");
                    } else if (oldData) {
                        CurrencyID = oldData.CurrencyID ? oldData.CurrencyID : ""
                    }
                    params = {
                        Mode: 2,
                        Amount:  data.ForeignCurrency,
                        CurrencyID: CurrencyID
                    };
                    if(params && params.Amount && params.CurrencyID) {
                        this.getApi(key, params, rowIndex);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    getApi =  (key, params,rowIndex) => {
        const {getConvertibleValueCurrentcies} = this.props;
        this.fieldUpdating = "";
        getConvertibleValueCurrentcies && getConvertibleValueCurrentcies(params, (error, _data) => {
            if (this.dataGrid && _data) {
                // let rowIndex = 0;
                // if (!this.addGrid && this.keyInserting) {
                //     rowIndex = this.dataGrid.instance.getRowIndexByKey(this.keyInserting);
                // }
                if (key === "CurrencyID" || key === "ForeignCurrency") {
                    this.dataGrid.instance.cellValue(rowIndex, "AmountVND", _data.AmountVND);
                    this.dataGrid.instance.cellValue(rowIndex, "AmountUSD", _data.AmountUSD);
                } else if (key === "AmountVND") {
                    this.dataGrid.instance.cellValue(rowIndex, "AmountUSD", _data.AmountUSD);
                } else if (key === "AmountUSD") {
                    this.dataGrid.instance.cellValue(rowIndex, "AmountVND", _data.AmountVND);
                }
                if(this.isAdding) {
                    this.isAdding = false;
                    if(this.isInserting) {
                        this.isInserting = false;
                        this.dataGrid.instance.saveEditData();
                    }
                }
            }
        });
    };

    onChange = (e, field) => {
        if (!(e && field)) return false;
        if (this.addGrid) {
            const value =  e.event ? e.event.target.value : e;
            const param = {
                newData: {[field]: value}
            };
            this.isAdding = true;
            this.onConvertibleValueCurrentcies(param);

        }
    };

    onCellClick = (e) => {
        this.selectedRowIndx = e.rowIndex;
    };

    formatNumber = (e) => {
        if(!e) return false;
        return <NumberFormat value={e.value} displayType={'text'} thousandSeparator={true} />
    };

    calculateCompanyPay = () => {
        const {calculateCompanyPay} = this.props;
        if (calculateCompanyPay) calculateCompanyPay();
    };
    onRowInserted = () => {
        this.addGrid = false;
        this.getDataTotal();
    };

    getDataTotal = () => {
        // this.setState({isEditing: false});
        this.calculateCompanyPay();

    };

    onEditingStart = (e) => {
        // const {data} = e;
        // this.keyInserting = data["__KEY__"];
        this.fieldUpdating = e.column.dataField;
        // if (!this.addGrid && !this.state.isEditing) {
        //     this.setState({isEditing: true});
        // }
    };

    render() {
        const {gridRef, classes, getCboCostTypes, dataCostWorkPlansOne} = this.props;
        return (
            <div className={"mgb25"} style={{width: '100%'}}>
                <GridContainer
                    reference={ref => {
                        gridRef && gridRef(ref);
                        this.dataGrid = ref;
                    }}
                    columnAutoWidth={true}
                    dataSource={dataCostWorkPlansOne}
                    elementAttr={{
                        style: "min-height: 200px"
                    }}
                    style={{width: "100%"}}
                    editing={{
                        mode: 'cell',
                        refreshMode: "reshape",
                        allowUpdating: true,
                        texts: {
                            confirmDeleteMessage: ""
                        },
                    }}
                    // filterRow={{
                    //     visible: true,
                    //     showOperationChooser: false,
                    // }}
                    allowColumnResizing={true}
                    hoverStateEnabled={true}
                    typeShort={true}
                    onInitNewRow={() => {
                        this.addGrid = true;
                        // this.setState({isEditing: true});
                    }}
                    onRowInserting={e => {
                        if(this.isAdding) {
                            e.cancel = true;
                            this.isInserting = true;
                        }
                    }}
                    onCellClick={this.onCellClick}
                    onRowInserted={this.onRowInserted}
                    onEditingStart={this.onEditingStart}
                    onRowUpdating={(e) => {
                        this.isAdding = true;
                        this.isInserting = true;
                        this.onConvertibleValueCurrentcies(e)
                    }}
                    // onRowInserting={this.getDataTotal}
                    // onRowUpdating={this.getDataTotal}
                    onRowUpdated={this.getDataTotal}
                >
                    <Column
                        caption={Config.lang("DHR_Hanh_dong")}
                        allowEditing={false}
                        width={110}
                        alignment={"center"}
                        visible={Config.isMobile}
                        cellRender={this.renderButtons}
                    />
                    <Column  caption={Config.lang("DHR_Khoan_chi")}
                             alignment={"left"}
                             dataField={'RecCostID'}
                             width={250}
                             lookup={{
                                 dataSource: getCboCostTypes,
                                 valueExpr: "RecCostID",
                                 displayExpr: "RecCostName",
                             }}
                             editorOptions={{ placeholder: Config.lang("DHR_Khoan_chi") + "...",
                             }}

                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        dataField="AmountVND"
                        alignment={"right"}
                        caption={Config.lang("DHR_Thanh_tien(VND)")}
                        dataType="number"
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        width={250}
                        cellRender={this.formatNumber}
                        editorOptions={{
                            onChange: (e) => {
                                this.addGrid && this.onChange(e, "AmountVND");
                            }
                        }}
                    />
                    <Column
                        caption={Config.lang("DHR_Thanh_tien(USD)")}
                        dataField={'AmountUSD'}
                        alignment={"right"}
                        dataType="number"
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        width={250}
                        editorOptions={{
                            onChange: (e) => {
                                this.addGrid && this.onChange(e, "AmountUSD");
                            }
                        }}
                    />

                    <Column
                        caption={Config.lang("DHR_Cong_ty_thanh_toan")}
                        dataField={'IsCompanyPay'}
                        width={150}
                        alignment={'center'}
                        cellRender={this.renderIsCompanyPay}
                        editCellRender={(e) => {
                            return <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"
                                              onChange={(chk) => {
                                                  e.component.cellValue(e.rowIndex, "IsCompanyPay", chk.target.checked ? 1 : 0);
                                              }}
                            />
                        }}
                    />
                    <Column
                        caption={Config.lang("DHR_Ghi_chu")}
                        alignment={"center"}
                        dataField={'Note'}
                    />
                    <Column
                        allowEditing={false}
                        visible={!Config.isMobile}
                        fixed={true}
                        fixedPosition={"right"}
                        cellRender={this.renderButtons}
                    />
                    <Summary recalculateWhileEditing={true}>
                        <TotalItem
                            column="RecCostID"
                            displayFormat={Config.lang("DHR_Tong")}
                        />
                        <TotalItem
                            column="AmountVND"
                            summaryType="sum"
                            valueFormat={"fixedPoint"}
                            displayFormat={'{0}'}
                        />
                        <TotalItem
                            column="AmountUSD"
                            summaryType="sum"
                            displayFormat={'{0}'}
                        />
                    </Summary>
                </GridContainer>
            </div>
        );
    }
}
W77F1013TableRecCost.propTypes  = {
    gridRef: PropTypes.func,
    calculateCompanyPay: PropTypes.func,
    dataCostWorkPlansOne: PropTypes.array,
    getCboCostTypes: PropTypes.array,
    getConvertibleValueCurrentcies: PropTypes.func,
    getFieldHide: PropTypes.any,
    onAddNewTableRestCost: PropTypes.func,
};


export default withStyles(styles)(W77F1013TableRecCost) ;