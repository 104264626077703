import React from "react";
import {bindActionCreators, compose} from "redux";
import {withStyles, IconButton, Divider, FormLabel as Label} from "@material-ui/core";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import {Image, FormGroup, Row, Col} from "react-bootstrap";
import Filter from "../../../filter/filter";
import {Combo, TextField} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import {browserHistory} from "react-router";

import * as W77F1010Actions from "../../../../redux/W7X/W77F1010/W77F1010_actions";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Icons from "../../../common/icons/";
import InlineSVG from "react-inlinesvg";
import _ from "lodash";


const styles = {

    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    iconStyle: {
        marginLeft: 20,
        fontSize: 20
    },
    iconSvgStyle: {
        marginLeft: 20,
        width: 10
    },
    textField: {
        '& .MuiFormHelperText-contained' : {
            margin: 0
        }
    }
};
class W77F1010 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            gridLoading: false,
            dateFrom: null,
            dateTo: null,
            loadingCboEmployees: false,
            loadingCboProjects: false,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            dataGrid: [],
            totalDataGrid: 0,
            error : {}
        };
        this.filter = {
            employeeID: '',
            appStatusID: '',
            fromCost: '',
            toCost: '',
            Project: '',
            skip: 0,
            limit: 10
        };
        this.filterCboEmployees = {
            strSearch: '',
            skip: 0,
            limit: 50
        }
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});

    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W77F1010", isPer => {
            this.setState({ iPermission: isPer });
        });
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    loadCboAppStatus = () => {
        const params = {
            Language : Config.language || '84',
            FormID: "STANDARD"
        };

        this.props.generalActions.getCboAppStatus(params, (error) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const { skip, limit, strSearch } = this.filterCboEmployees;
        const params = {
            FormID: "W77F1010",
            Language : Config.language || '84',
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch ? strSearch : ''

        };
        this.setLoading('CboEmployees', true);
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setLoading('CboEmployees', false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset) => {
        const { skip, limit, strSearch } = this.filterCboProjects;
        const params = {
            HostID: "",
            FormID: "W25F2000",
            Language : Config.language || '84',
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch ? strSearch : ''
        };
        this.setLoading('CboProjects', true);
        this.props.generalActions.getCboProjects(params, (error, data) => {
            this.setLoading('CboProjects', false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    filterChange = (key,data) => {
        if(!key) return false;
        const { fromCost, toCost } = this.filter;
        const { value } = data;
        if (key === "FromCost") {
            if (Number(data.target.value) > Number(toCost)) {
                this.setStateErrorText({"FromCost": Config.lang("DHR_So_tien_tu_khong_duoc_lon_hon_so_tien_den")});
            } else {
                this.setStateErrorText({"FromCost": "","ToCost": ""});
            }
        }
        if (key === "ToCost") {
            if (Number(data.target.value) < Number(fromCost)) {
                this.setStateErrorText({"ToCost": Config.lang("DHR_So_tien_den_khong_duoc_nho_hon_so_tien_tu")});
            } else {
                this.setStateErrorText({"ToCost": "","FromCost": ""});
            }
        }
        switch (key) {
            case "DateFrom":
                this.setState({dateFrom: value});
                break;
            case "DateTo":
                this.setState({dateTo: value});
                break;
            case "AppStatusID":
                this.filter.appStatusID = value;
                break;
            case "EmployeeID":
                this.filter.employeeID = value;
                break;
            case "FromCost":
                this.filter.fromCost = data.target.value;
                break;
            case "ToCost":
                this.filter.toCost = data.target.value;
                break;
            default:
                break;
        }
    };

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { skip, limit, employeeID, appStatusID, fromCost, toCost } = this.filter;
        const { dateFrom ,dateTo } = this.state;
        const params = {
            TransID: VoucherID || "",
            DivisionID : Config.getDivisionID(),
            FormID: "W77F1010",
            DateFrom: dateFrom ? dateFrom : null,
            DateTo: dateTo ? dateTo : null,
            EmployeeID: employeeID && employeeID.EmployeeID ? employeeID.EmployeeID : '',
            FromCost: fromCost ? Number(fromCost) : null,
            ToCost: toCost ? Number(toCost) : null,
            Language: Config.language || '84',
            AppStatusID: appStatusID || appStatusID === 0 ? String(appStatusID) : "",
            skip: skip,
            limit: limit
        };
        this.setState({gridLoading: true});
        this.props.w77F1010Actions.getDataGrid(params, (error,data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            this.setState({gridLoading: false});
            if(data) {
                this.setState({
                    dataGrid: data.rows ? data.rows : data,
                    totalDataGrid: data.total,
                });
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadCboAppStatus();
        this.loadCboEmployees();
        this.loadDataGrid();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.state;
        const {FormID} = Config.getUrlParams(this.props);
        const mode = FormID === "W77F1013" ? "calculate" : "edit";
        Config.callChildForm({
            FormID: "W77F1010",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: dataGrid || [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.redirectScreen(mode, { data: data })
        }, this.props);
    }

    onSearch = () => {
        this.loadDataGrid();
    };

    renderFilter = () => {
        const { loadingCboEmployees, dataCboEmployees, error, dateFrom ,dateTo } = this.state;
        const { getCboAppStatus, classes } = this.props;
        const { employeeID, appStatusID, fromCost, toCost } = this.filter;
        return (
            <Filter
                placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                error={error && error['DateFrom']}
                                                max={dateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                stylingMode={"outlined"}
                                                shrink={true}
                                                margin={"normal"}
                                                value={dateFrom}
                                                onValueChanged={e => this.filterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <div className={"display_row align-right"}>
                                                <DateBoxPicker
                                                    error={error && error['DateTo']}
                                                    min={dateFrom}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    width={"100%"}
                                                    stylingMode={"outlined"}
                                                    margin={"normal"}
                                                    value={dateTo}
                                                    onValueChanged={e => this.filterChange("DateTo", e)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={appStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={employeeID}
                                            loading={loadingCboEmployees}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <TextField
                                                error={error && error["FromCost"]}
                                                label={Config.lang('DHR_Chi_phi')}
                                                variant={"outlined"}
                                                InputProps={{
                                                    min: 0,
                                                }}
                                                value={String(fromCost)}
                                                onChange={e => this.filterChange("FromCost", e)}
                                                size={"small"}
                                                fullWidth
                                            />
                                        </div>

                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <TextField
                                                error={error && error["ToCost"]}
                                                label={Config.lang('DHR_Chi_phi')}
                                                variant={"outlined"}
                                                InputProps={{
                                                    min: 0,
                                                }}
                                                className={classes.textField}
                                                size={"small"}
                                                value={String(toCost)}
                                                fullWidth
                                                onChange={e => this.filterChange("ToCost", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "38px",
                                    paddingBottom: "40px"
                                }}
                            >
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={'search'}
                                    color={"primary"}
                                    variant={"outlined"}
                                    style={{textTransform: "uppercase"}}
                                    size={"large"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };

    renderEmpProfile = e => {
        const {classes} = this.props;
        const {data} = e.row;
        const date = data.ProposalDate ? moment.utc(data.ProposalDate).format("DD/MM/YYYY, HH:mm:ss") : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}>
                        <UserName data={data}/>
                    </div>
                    <div className={classes.divText}>
                        {data.DepartmentName || ""}
                    </div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{paddingLeft: "8px"}} className={"date-text-info"}>
                            {data.ProposalDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const { classes } = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>
                    {Config.lang("DHR_Ma")}:{data.BusinessTravelCode || ""}
                </div>
                {!isNaN(data.PlanAmount) && +data.PlanAmount >=0 && (
                    <div>
                        {Config.lang("DHR_Tong_chi_phi_du_kien")}: { new Intl.NumberFormat().format(data.PlanAmount) + ' đ'}
                    </div>
                )}
                {
                    (data.StatusRegisterEX  === 1 || data.StatusRegisterEX  === 2) &&
                    !isNaN(data.ActualAmount) && +data.ActualAmount >=0  && (
                        <div>
                            {Config.lang("DHR_Tong_chi_phi")}: { new Intl.NumberFormat().format(data.ActualAmount) + ' đ'}
                        </div>
                    )
                }

                {!isNaN(data.ProAmountVND) && +data.ProAmountVND >=0 &&  (
                    <div>
                        {Config.lang("DHR_Tam_ung")}: {new Intl.NumberFormat().format(data.ProAmountVND) + ' đ'}
                        { data.StatusRegisterEX  === 1  && <InlineSVG className={classes.iconSvgStyle} src={require("../../../../../src/assets/images/dollar-sign-solid.svg")} />}
                    </div>
                )}
                {data.Reimburse && data.StatusRegisterEX  === 2 && (
                    <div>
                        {Config.lang("DHR_Quyet_Toan")}: { new Intl.NumberFormat().format(Math.abs(data.Reimburse)) + ' đ' || ""}
                        <span className={classes.iconStyle}><Icons className={"fas fa-hand-holding-usd"}/></span>
                    </div>
                )
                }
                {data.BusinessLocation && (
                    <div>
                        {Config.lang("DHR_Dia_diem")}: { data.BusinessLocation || ""}
                    </div>
                )}
                {data.BTApproverName && (
                    <div>
                        {Config.lang("DHR_Duyet_cong_tac")}: {data.BTApproverName || ""} - {data.DepartmentIDBTApp || ""} - {moment.utc(data.BTAppDate).format( "DD/MM/YYYY") || ""}
                    </div>
                )}
                {data.EXApproverName && data.StatusRegisterEX > 0  && (
                    <div>
                        {Config.lang("DHR_Duyet_quyet_toan_chi_phi")}: {data.EXApproverName || ""} - {data.DepartmentIDEXApp || ""} - {moment.utc(data.EXAppDate).format( "DD/MM/YYYY") || ""}
                    </div>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const { data } = e.row;
        let statusName = "";
        let key = "";
        if(Number(data.StatusRegisterEX) === 0) {
            key = data.AppStatusID;
            statusName = data.AppStatusName;
        }
        if(Number(data.StatusRegisterEX) !== 0) {
            key = data.AppStatusExID;
            statusName = data.AppStatusExName;
        }
        let status = {
            AppStatusID: key,
            AppStatusName: statusName
        };
        return (
            <Status data={status} />
        );
    };

    redirectScreen = (mode, e = "") => {
        const { iPermission } = this.state;
        const TransID = _.get(e, "data.TransID", "");
        let screen = '';
        switch (mode) {
            case "add":
                screen = "W77F1011";
                break;
            case "edit":
                screen = "W77F1011";
                break;
            case "calculate":
                screen = "W77F1013";
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: {
                mode: mode,
                isPermissionForm: iPermission,
                TransID: TransID,
                ...(e && e.data ? { data: e.data } : {})
            }
        });
    };

    onDelete = (data)=>{
        const { TransID } = data;
        const param = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1010",
            Language: Config.language || '84',
            TransID:TransID,
        };

        Config.popup.show('YES_NO',Config.lang("DHR_Ban_co_chac_muon_xoa?"),
            () =>{
                this.props.w77F1010Actions.deleteDataGrid(param, (error) => {
                    if (error) {
                        let message = Config.lang("DHR_Loi_chua_xac_dinh");
                        Config.popup.show('ERROR', message);
                        return false;
                    }
                    else{
                        Config.notify.show('success', Config.lang("DHR_Xoa_thanh_cong"), 2000);
                        this.loadDataGrid();
                    }
                });
            });
    };

    onEdit = (e) => {
        this.redirectScreen("edit", e);
    };

    onAdd = () => {
        this.redirectScreen("add");
    };

    goToPageW77F1013 = (e) => {
        this.redirectScreen('calculate',e)
    };

    renderAction = e => {
        const { iPermission } = this.state;
        const { data } = e.row;
        const allowView = (iPermission > 0);
        const allowIconDollar = (iPermission > 1) && (Number(data.AppStatusID) === 2);
        const allowDelete = (iPermission > 3) && ( Number(data.AppStatusID) === 0);
        return (
            <div className={"display_row align-center"}>
                <IconButton
                    disabled={!allowIconDollar}
                    aria-label="dollar"
                    size="small"
                    onClick={() => this.goToPageW77F1013(e)}
                >
                    <Icons className={"fas fa-dollar-sign"} style={{fontSize: 16}} width={12} height={10} />
                </IconButton>
                <Divider
                    orientation="vertical"
                    style={{ width: 2, height: 11, margin: "0 5px" }}
                />
                <IconButton
                    disabled={!allowView}
                    aria-label="view"
                    size="small"
                    onClick={() => this.onEdit(e)}
                >
                    <Icons name={"view"}/>
                </IconButton>

                <Divider
                    orientation="vertical"
                    style={{ width: 2, height: 11, margin: "0 5px" }}
                />
                <IconButton
                    disabled={!allowDelete}
                    aria-label="delete"
                    size="small"
                    onClick={() => this.onDelete(data)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        );
    };

    render() {
        const { skip, limit } = this.filter;
        const { gridLoading, iPermission, dataGrid, totalDataGrid } = this.state;
        if (!iPermission) return null;
        return (
            <>
                <ActionToolbar title={Config.lang("DHR_Quan_ly_cong_tac_va_chi_phi")}>
                    <ButtonGeneral
                        disabled={!(iPermission > 1)}
                        name={Config.lang("DHR_Them")}
                        typeButton={"add"}
                        style={{textTransform: "uppercase"}}
                        size={"large"}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <GridContainer
                    style={{border: "none"}}
                    dataSource={dataGrid}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("DHR_No_data")}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={totalDataGrid}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile}/>
                    <Column cellRender={this.renderInfo}/>
                    <Column cellRender={this.renderStatus}  minWidth={150} maxWidth={200}/>
                    <Column cellRender={this.renderAction}  width={150}/>
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect((state) => ({
            getCboAppStatus: state.general.getCboAppStatus,
        }),
        dispatch => ({
            w77F1010Actions: bindActionCreators(W77F1010Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W77F1010);
