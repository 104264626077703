
import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {Col, Row, FormGroup} from "react-bootstrap";
import { Combo, TextField} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ButtonGeneral from "../../../common/button/button-general";
import moment from 'moment';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary, FormControl, InputLabel,
    Typography
} from "@material-ui/core";
import Attachments from "../../../common/attachments/attachments";
import MForm from "../../../common/form-material/form";
import _ from "lodash";
import NumberFormat from 'react-number-format';
import {browserHistory} from "react-router";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InputAdornment from "@material-ui/core/InputAdornment";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import W77F1011TableInfo from "./W77F1011TableInfo";
import * as w77F1011Actions from "../../../../redux/W7X/W77F1011/W77F1011_actions";
import W77F1011TableContent from "./W77F1011TableContent";
import IconButton from "@material-ui/core/IconButton";
import Icons from "../../../common/icons";
const styles = theme => {
    return {
        heading: {
          marginRight: 5
        },
        noneBorder: {
            margin:'0px!important',
            "&:before": {
                backgroundColor: "transparent"
            }
        },
        ExpansionPanelSummary: {
            padding: 0,
            maxWidth: 300,
            color: theme.palette.primary.main,
            "& .MuiExpansionPanelSummary-content": {
                margin: "12px 0"
            },
            "&.Mui-expanded": {
                minHeight: "inherit"
            }
        }
    };
};

class W77F1011 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataHide: [],
            iPermission: 0,
            error: {},
            modeInfo: "",
            modeContent: "",
            loadingProjects: false,
            expandedFirst: false,
            expandedSecond: false,
            count: null,
            isSave: false,
            disabled: false,
            dataCostWorkPlans: [],
            dataWorkingDiary: [],
            disableGridInfo: false,
            formData: {
                Employee: null,
                FromDate: null,
                ToDate: null,
                BusinessTravelCode: "",
                BusinessLocation: "",
                IsDomestic: 0,
                Content: "",
                ProAmountVND: 0,
                ProAmountUSD: 0,
                ProDateAdvance: null,
                Reason: ""
            },
            dataOldAttachments: [],
            uploading: false,
            formLoading: false,
        };

        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.dataGridInfo = null;
        this.dataGridContent = null;
        this.filterCboProjects = {
            skip: 0,
            limit: 50,
            strSearch: ""
        }
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});

    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = e => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    loadAttachments = () => {
        const { TransID, voucherID } = this.getInfo();
        const param = {
            KeyID: TransID ? TransID : voucherID,
            TableName: "D15T2050"
        };
        if (_.isEmpty(param.KeyID)) {
            Config.popup.show("ERROR", Config.lang("DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem"));
            return false;
        }
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if (data) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = () => {
        let arrDataCosts = [];
        let arrDatadiary = [];

        if (this.dataGridInfo) {
             this.dataGridInfo.instance.saveEditData();
            let dataCosts = this.dataGridInfo.instance.option("dataSource");
            if(dataCosts && dataCosts.length > 0) {
                dataCosts.forEach(item => {
                    dataCosts = {
                        RecCostID: item.RecCostID,
                        AmountVND: item.AmountVND,
                        AmountUSD: item.AmountUSD,
                        IsCompanyPay: item.IsCompanyPay,
                        CurrencyID: item.CurrencyID,
                        ExchangeRate: item.ExchangeRate,
                        ForeignCurrency: item.ForeignCurrency,
                        Note: item.Note
                    };
                    arrDataCosts.push(dataCosts)
                })
            } else {

            }
        }
        if (this.dataGridContent) {
            this.dataGridContent.instance.saveEditData();
            let datadiary  = this.dataGridContent.instance.option("dataSource");
            if(datadiary && datadiary.length > 0) {
                datadiary.forEach(item => {
                    datadiary = {
                        Content: item.Content,
                        Departure: item.Departure,
                        Destination: item.Destination,
                        ToDate: moment(item.ToDate).format("YYYY-MM-DD"),
                        FromDate: moment(item.FromDate).format("YYYY-MM-DD")
                    };
                    arrDatadiary.push(datadiary)
                })
            }

        }
        const { location } = this.props;
        const mode = _.get(location, "state.mode", "add");
        const apiSave = mode === "add" ? mode : "edit";
        const TransID = _.get(location, "state.TransID", "");
        const { formData } = this.state;
        const  {
            Employee,
            FromDate,
            ToDate,
            BusinessLocation,
            IsDomestic,
            Content,
            ProAmountVND,
            ProAmountUSD,
            ProDateAdvance,
            Reason
        } = formData;

        const validateRules = [
            {
                name: "EmployeeID",
                rules: "isRequired",
                value: Employee
            },
            {
                name: "FromDate",
                rules: "isRequired",
                value: FromDate
            },
            {
                name: "ToDate",
                rules: "isRequired",
                value: ToDate
            },
            {
                name: "BusinessLocation",
                rules: "isRequired",
                value: BusinessLocation
            },
            {
                name: "Content",
                rules: "isRequired",
                value: Content
            },
        ];
        const validateFrom = MForm.formValidation(validateRules);
        if (Object.keys(validateFrom).length > 0) {
            this.setStateErrorText(validateFrom);
            return false;
        }
        const arrAttachments = this._getAttachments();
        let params = {
            // TransID: data.TransID,
            FormID: "W77F1011",
            Language: Config.language || 84,
            EmployeeID: Employee ? Employee.EmployeeID : "",
            FromDate: FromDate ? moment(FromDate).format("YYYY-MM-DD") : null,
            ToDate: ToDate ? moment(ToDate).format("YYYY-MM-DD") : null,
            BusinessLocation: BusinessLocation,
            Content: Content ? Content : "",
            IsDomestic: IsDomestic,
            ProAmountVND: ProAmountVND ? ProAmountVND : 0,
            ProAmountUSD: ProAmountUSD ? ProAmountUSD : 0,
            ProDateAdvance: ProDateAdvance ? moment(ProDateAdvance).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            Reason: Reason ? Reason : "",
            costs: arrDataCosts ? JSON.stringify(arrDataCosts) : "",
            diaries: arrDatadiary ? JSON.stringify(arrDatadiary) : "",
            arrAttachment: arrAttachments ? JSON.stringify(arrAttachments) : ""
        };
        if (TransID && apiSave === "edit") params = { ...params, TransID };
        this.setState({ formLoading: true });
        this.props.w77F1011Actions[apiSave](params, (errors, data) => {
            this.setState({ formLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
            if (data.Status === 1) {
                const msg = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', msg);
                return false;
            } else {
                Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                this.setState({isSave: true});
            }

        });
    };

    getInfo = (flag, nextProps) => {
        const { location } = nextProps ? nextProps : this.props;
        if (location && location.state) {
            const voucher_id = location.state.voucher_id ? location.state.voucher_id : "";
            return {
                voucherID: voucher_id,
                mode: location.state.mode ? location.state.mode : (voucher_id ? 'edit' : 'add'),
                isPermissionForm: location.state.isPermissionForm ? location.state.isPermissionForm  : "",
                TransID: location.state.TransID ? location.state.TransID  : "",
                data: location.state.data ? location.state.data  : "",
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W77F1010");
                return null;
            }
            return false;
        }
    };
    handleChange = (key,e) => {
        if(!key) return false;
        const { getFieldHide }= this.props;
        const {UseCurency} = getFieldHide;
        const { value }  = e;
        switch (key) {
            case "EmployeeID":
                this.setState({formData: {
                        ...this.state.formData,
                       Employee: value
                    }});
                break;
            case "FromDate":
                this.setState({formData: {
                        ...this.state.formData,
                        FromDate: value
                    }});
                break;
            case "ToDate":
                this.setState({formData: {
                        ...this.state.formData,
                        ToDate: value
                    }});
                break;
            case "BusinessTravelCode":
                this.setState({formData: {
                        ...this.state.formData,
                        BusinessTravelCode: value
                    }});
                break;
            case "BusinessLocation":
                this.setState({formData: {
                        ...this.state.formData,
                        BusinessLocation: e.target.value
                    }});
                break;
            case "IsDomestic":
                this.setState({formData: {
                        ...this.state.formData,
                        IsDomestic: e.target.value
                    }});
                break;
            case "Content":
                this.setState({formData: {
                        ...this.state.formData,
                        Content: e.target.value
                    }});
                break;
            case "ProAmountVND":
                this.setState({formData: {
                        ...this.state.formData,
                        ProAmountVND: e.target.value
                    }}, () => {
                    if(UseCurency === 2) {
                        let data = {
                            Mode: 0,
                            CurrencyID: "",
                            Amount:  e.target.value
                        };
                        this.loadConvertibleValueCurrentcies(data, (error, data) => {
                            this.setState({formData: {
                                    ...this.state.formData,
                                    ProDateAdvance: data.AmountUSD
                                }});
                        });
                    }
                });
                break;
            case "ProAmountUSD":
                this.setState({formData: {
                        ...this.state.formData,
                        ProAmountUSD: e.target.value
                    }});
                break;
            case "ProDateAdvance":
                this.setState({formData: {
                        ...this.state.formData,
                        ProDateAdvance: value
                    }});
                break;
            case "Reason":
                this.setState({formData: {
                        ...this.state.formData,
                        Reason: e.target.value
                    }});
                break;
            default:
                break;
        }
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W77F1010", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboCostTypes = () => {
        this.props.w77F1011Actions.getCboCostTypes(error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadFieldHide = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1011"
        };

        this.props.w77F1011Actions.getFieldHide(params,error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadInfoBusinessTrips = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1011",
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1011Actions.getInfoBusinessTrips(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({formData: {
                        ...this.state.formData,
                        Employee: data,
                        FromDate: data.FromDate,
                        ToDate: data.ToDate,
                        BusinessTravelCode: data.BusinessTravelCode,
                        BusinessLocation: data.BusinessLocation,
                        IsDomestic: data.IsDomestic,
                        Content: data.Content,
                        ProAmountVND: data.ProAmountVND,
                        ProAmountUSD: data.ProAmountUSD,
                        ProDateAdvance: data.ProDateAdvance,
                        Reason: data.Reason
                }});
            }
        });
    };

    loadCostWorkPlans = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1011",
            Mode: 0,
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1011Actions.getCostWorkPlans(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                let ProAmountVND = 0;
                let ProAmountUSD = 0;
                data.forEach(item => {
                    if (!item.IsCompanyPay) {
                        ProAmountVND += (item.AmountVND || 0);
                        ProAmountUSD += (item.AmountUSD || 0);
                    }
                });
                this.setState({dataCostWorkPlans: data, formData: {...this.state.formData,ProAmountVND: ProAmountVND,ProAmountUSD: ProAmountUSD }});
            }
        })

    };

    loadGetWorkingDiary = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1011",
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1011Actions.getWorkingDiary(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({dataWorkingDiary: data});
            }
        });
    };

    loadConvertibleValueCurrentcies = (data, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            Mode: data ? data.Mode : 0,
            CurrencyID: data ? data.CurrencyID : "",
            Amount: data ? data.Amount : 0
        };
            this.props.w77F1011Actions.getConvertibleValueCurrentcies(params,(error, data) => {
                if (error) {
                    let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show("ERROR", message);
                    return false;
                }
                if(data) {
                    cb && cb(error, data);
                }

            });
    };

    loadCurrency = () => {
        this.props.generalActions.getLoadCurrency({}, (error) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.getInfo()) return;
        const { mode, data } = this.getInfo();
        if(mode && mode === "edit") {
            if(Number(data.AppStatusExID) > 0) {
                this.setState({disabled: true})
            }
            this.loadInfoBusinessTrips();
            this.loadCostWorkPlans();
            this.loadGetWorkingDiary();
            this.loadAttachments();
        }
        this.loadCurrency();
        this.loadCboCostTypes();
        this.loadFieldHide();
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W77F1010");
    };

    onAddNewInfo = () => {
        this.setState({modeInfo: "add",expandedFirst: true}, () => {
            setTimeout(() => {
                if (this.dataGridInfo) {
                    this.dataGridInfo.instance.addRow();
                }
            }, 300);
        });
    };

    onAddNewContent = () => {
        this.setState({modeContent: "add",expandedSecond: true}, () => {
            setTimeout(() => {
                if (this.dataGridContent) {
                    this.dataGridContent.instance.addRow();
                }
            }, 300);
        });
    };

    scrollTopButton = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    calculateCompanyPay = () => {
        if (this.dataGridInfo) {
            const dataSource = this.dataGridInfo.instance.option("dataSource");
            let ProAmountVND = 0;
            let ProAmountUSD = 0;
            dataSource.forEach(item => {
                if (!item.IsCompanyPay) {
                    ProAmountVND += (item.AmountVND || 0);
                    ProAmountUSD += (item.AmountUSD || 0);
                }
            });
            this.setState({formData: {...this.state.formData,ProAmountVND: ProAmountVND,ProAmountUSD: ProAmountUSD }});
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { mode, data, voucherID } = this.getInfo();
        const {classes, getCboCostTypes, getFieldHide, getLoadCurrency} = this.props;
        const { formData, expandedFirst, expandedSecond, error,
            dataOldAttachments, formLoading, modeInfo, iPermission, modeContent, dataCostWorkPlans, isSave, dataWorkingDiary, disabled } = this.state;
        const {Employee, FromDate, ToDate, BusinessTravelCode, BusinessLocation, IsDomestic,
            Content, ProAmountVND, ProAmountUSD, ProDateAdvance, Reason} = formData;
        const {UseCurency} = getFieldHide;
        if (iPermission <= 0) return null;
        return (
            <>
                <ActionToolbar  title={Config.lang("DHR_Dang_ky_cong_tac")} alignment={"flex-end"} onBack={this.onBack}>
                    <ButtonGeneral name={Config.lang('DHR_Luu')}
                                   typeButton={'save'}
                                   disabled={formLoading || isSave || disabled || (data && data.AppStatusID && Number(data.AppStatusID) !== 0) || !!voucherID}
                                   loading={formLoading}
                                   style={{ textTransform: 'uppercase' }}
                                   size={"large"}
                                   onClick={this.onSave} />
                </ActionToolbar>
                <div className={"pdt10 pdb10 display_row align-center flex-wrap"}>

                    <Typography style={{fontSize: "15px", fontWeight:"bold"}}>
                        {Config.lang("DHR_Thong_tin_chung")}
                    </Typography>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={3} lg={3}>
                        <Combo.Employees
                            error={error && error["EmployeeID"]}
                            acceptCustomValue={true}
                            displayExpr={'EmployeeName'}
                            keyExpr={'EmployeeID'}
                            valueExpr={'EmployeeID'}
                            subTextExpr={"EmployeeID"}
                            value={(iPermission > 2) ? Employee :  Config.getHREmployeeID() }
                            disabled={!(iPermission > 2) || disabled || !!voucherID }
                            FormID={"W77F1010"}
                            stylingMode={'underlined'}
                            shrink={true}
                            required={true}
                            label={Config.lang('DHR_Nhan_vien')}
                            onValueChanged={e => this.handleChange("EmployeeID", e)}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3}>
                        <DateBoxPicker
                            error={error && error['FromDate']}
                            label={Config.lang("DHR_Ngay_di")}
                            max={ToDate}
                            useMaskBehavior={true}
                            showClearButton={true}
                            width={"100%"}
                            disabled={disabled || !!voucherID }
                            stylingMode={"underlined"}
                            shrink={true}
                            required={true}
                            margin={"normal"}
                            value={FromDate}
                            onValueChanged={e => this.handleChange("FromDate", e)}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3}>
                        <DateBoxPicker
                            error={error && error['ToDate']}
                            shrink={true}
                            label={Config.lang("DHR_Ngay_ve")}
                            min={FromDate}
                            disabled={disabled || !!voucherID }
                            useMaskBehavior={true}
                            showClearButton={true}
                            required={true}
                            width={"100%"}
                            stylingMode={"underlined"}
                            margin={"normal"}
                            value={ToDate}
                            onValueChanged={e => this.handleChange("ToDate", e)}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            error={error && error['BusinessTravelCode']}
                            label={Config.lang("DHR_Ma_cong_tac")}
                            variant={"standard"}
                            value={BusinessTravelCode}
                            required={true}
                            InputLabelProps={{
                                shrink: true,
                                readOnly: true
                            }}
                            inputProps={{ readOnly: true }}
                            disabled={disabled || !!voucherID }
                            onChange={(e) => this.handleChange('BusinessTravelCode', e)}
                            margin={"normal"}
                            fullWidth
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={8}>
                        <TextField
                            error={error && error['BusinessLocation']}
                            label={Config.lang("DHR_Dia_diem")}
                            variant={"standard"}
                            value={BusinessLocation}
                            required={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            disabled={disabled || !!voucherID }
                            onChange={(e) => this.handleChange('BusinessLocation', e)}
                            margin={"normal"}
                            fullWidth
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>

                        <FormControl variant={"standard"}
                                     margin={"normal"}
                                     fullWidth={true} style={{padding: "20px 0px 0px 0px"}}>
                            <InputLabel
                                shrink={true}
                            >
                            </InputLabel>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <RadioGroup style={{flexDirection: "row"}}  value={String(IsDomestic)} onChange={e => this.handleChange("IsDomestic", e)}>
                                        <FormControlLabel value="0" disabled={disabled || !!voucherID } control={<Radio />} label={Config.lang("DHR_Trong_nuoc")} />
                                        <FormControlLabel value="1" disabled={disabled || !!voucherID } control={<Radio />} label={Config.lang("DHR_Nuoc_ngoai")} />
                                    </RadioGroup>
                                </Col>
                            </Row>
                        </FormControl>
                    </Col>

                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            error={error && error['Content']}
                            label={Config.lang("DHR_Muc_dich_cong_tac")}
                            variant={"standard"}
                            margin={"normal"}
                            value={Content}
                            required={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            disabled={disabled || !!voucherID }
                            fullWidth
                            onChange={e => this.handleChange("Content", e)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8}>
                        <FormControl variant={"standard"}
                                     margin={"normal"}
                                     fullWidth={true}>
                            <InputLabel
                                shrink={true}
                            >
                                {Config.lang("DHR_So_tien_de_xuat_tam_ung")}
                            </InputLabel>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        variant={"standard"}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">đ</InputAdornment>,
                                            min : 0,
                                            inputComponent: NumberFormatCustom
                                        }}
                                        disabled={disabled || !!voucherID }
                                        value={String(ProAmountVND)}
                                        onChange={(e) => this.handleChange("ProAmountVND",e)}
                                        margin={"normal"}
                                        multiline
                                        fullWidth
                                    />
                                </Col>
                                {UseCurency === 2 &&
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <TextField
                                            variant={"standard"}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                min : 0,
                                                inputComponent: NumberFormatCustom,
                                                startAdornment: (
                                                    <AttachMoneyIcon style={{fontSize: 16, color: "#9E9E9E"}}/>
                                                ),
                                            }}
                                            disabled={disabled || !!voucherID }
                                            value={String(ProAmountUSD)}
                                            onChange={(e) => this.handleChange("ProAmountUSD",e)}
                                            margin={"normal"}
                                            multiline
                                            fullWidth
                                        />
                                    </Col>
                                }

                            </Row>
                        </FormControl>

                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4}>
                        <DateBoxPicker
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={error && error['ProDateAdvance']}
                            label={Config.lang("DHR_Ngay_de_xuat")}
                            useMaskBehavior={true}
                            showClearButton={true}
                            width={"100%"}
                            disabled={disabled || !!voucherID }
                            stylingMode={"underlined"}
                            margin={"normal"}
                            value={ProDateAdvance}
                            onValueChanged={e => this.handleChange("ProDateAdvance", e)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            label={Config.lang("DHR_Ly_do_tam_ung")}
                            variant={"standard"}
                            margin={"normal"}
                            value={Reason}
                            InputLabelProps={{
                                shrink: true
                            }}
                            disabled={disabled || !!voucherID }
                            fullWidth
                            onChange={e => this.handleChange("Reason", e)}
                        />

                    </Col>
                </Row>

                {/*<Container maxWidth="lg" style={{ paddingTop: 20, marginLeft: 0 }}>*/}
                    <ExpansionPanel
                        expanded={expandedFirst}
                        className={classes.noneBorder}
                        style={{ boxShadow: "none" }}
                        onChange={() => this.setState({ expandedFirst: !expandedFirst })}
                    >
                        <div className={"display_row align-center"}>
                            <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummary}
                            >
                                <i
                                    className={"fa display_row align-center " + (expandedFirst ? "fa-angle-down" : "fa-angle-right")}
                                    style={{ fontSize: 20, width: 20 }}
                                />
                                <Typography className={classes.heading}>{Config.lang("DHR_Khoan_chi_phi_du_kien")}</Typography>
                            </ExpansionPanelSummary>
                            <ButtonGeneral
                                typeButton={"add"}
                                disabled={disabled || !!voucherID }
                                size={"small"}
                                variant={"outlined"}
                                onClick={this.onAddNewInfo}
                            />
                        </div>

                        <ExpansionPanelDetails style={{ padding: 0,width: "100%" }}>
                            <W77F1011TableInfo
                            gridRef={ref => this.dataGridInfo = ref}
                            dataCostWorkPlans={dataCostWorkPlans}
                            modeInfo={modeInfo}
                            mode={mode}
                            getFieldHide={getFieldHide}
                            IsDomestic={IsDomestic}
                            getCboCostTypes={getCboCostTypes}
                            getLoadCurrency={getLoadCurrency}
                            calculateCompanyPay={this.calculateCompanyPay}
                            getConvertibleValueCurrentcies={this.loadConvertibleValueCurrentcies}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                        expanded={expandedSecond}
                        className={classes.noneBorder}
                        style={{ boxShadow: "none"}}
                        onChange={() => this.setState({ expandedSecond: !expandedSecond })}
                    >
                        <div className={"display_row align-center"}>
                            <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummary}
                            >
                                <i
                                    className={"fa display_row align-center " + (expandedSecond ? "fa-angle-down" : "fa-angle-right")}
                                    style={{ fontSize: 20, width: 20 }}
                                />
                                <Typography className={classes.heading}>{Config.lang("DHR_Nhat_ky_cong_tac")}</Typography>
                            </ExpansionPanelSummary>
                            <ButtonGeneral
                                typeButton={"add"}
                                size={"small"}
                                disabled={disabled || !!voucherID}
                                variant={"outlined"}
                                onClick={this.onAddNewContent}
                            />
                        </div>
                        <ExpansionPanelDetails style={{ padding: 0,width: "100%" }}>
                            <W77F1011TableContent
                                gridRef={ref => this.dataGridContent = ref}
                                modeContent={modeContent}
                                mode={mode}
                                dataWorkingDiary={dataWorkingDiary}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                {/*</Container>*/}

                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{zIndex: 1, marginTop: 25}}>
                            <Attachments
                                isAttachInfo
                                ref={ref => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={formLoading || isSave ||  (data && data.AppStatusID !== "" && Number(data.AppStatusID) !== 0) || disabled || !!voucherID}
                                maxLength={5}
                                uploading={formLoading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <ButtonGeneral name={Config.lang('DHR_Luu')}
                               typeButton={'save'}
                               disabled={formLoading || isSave || disabled || (data && data.AppStatusID && Number(data.AppStatusID) !== 0) || !!voucherID }
                               loading={formLoading}
                               style={{ textTransform: 'uppercase',float: 'right' }}
                               size={"large"}
                               onClick={this.onSave} />

                <ActionToolbar alignment={"flex-end"} style={{border: 'none'}}>
                    <IconButton aria-label="view" style={{position: "fixed", bottom: 20, zIndex: 10}} className={"btn-grid-action mgr5"}
                                size={"medium"}
                                onClick={this.scrollTopButton}
                    >
                        <Icons name={"up_filled"} width={"30px"} style={{marginTop: "10px"}} height={"30px"}/>
                    </IconButton>
                </ActionToolbar>
            </>
        )
    }
}

class NumberFormatCustom extends Component {

    render() {
        const {name, inputRef, onChange, ...props} = this.props;
        return (
            <NumberFormat
                {...props}
                // value={value}
                getInputRef={inputRef}
                thousandSeparator={true}
                onValueChange={(e) => {
                    if (onChange) {
                        onChange({
                            target: {
                                name: name,
                                value: e.value
                            }
                        })
                    }
                }}
            />
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboCostTypes: state.W77F1011.getCboCostTypes,
            getFieldHide: state.W77F1011.getFieldHide,
            getLoadCurrency: state.general.getLoadCurrency,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w77F1011Actions: bindActionCreators(w77F1011Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(W77F1011);