/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 8/21/2020
 * @Example
 */
import React, {PureComponent} from 'react';
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import {Column, Summary, TotalItem} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import UndoIcon from '@material-ui/icons/Undo';
import IconButton from "@material-ui/core/IconButton";
import Icons from "../../../common/icons";
import NumberFormat from 'react-number-format';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W77F1013Actions from "../../../../redux/W7X/W77F1013/W77F1013_actions";

class W77F1013TableAllocation extends PureComponent {
    renderButtons = (e) => {
        return (
           <div className={"grid-action-hover hidden"}>
               {
                   !e.row.isEditing ?
                       <IconButton
                           aria-label="view"
                           size={"small"}
                           style={{ padding: 0, marginRight: 4 }}
                           onClick={() => e.component.deleteRow(e.rowIndex)}
                       >
                           <Icons name={"delete"} />
                       </IconButton>
                       : (
                           <>
                               <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                           size={"small"}
                                           onClick={() => e.component.saveEditData()}
                               >
                                   <Icons name={"save_filled"} type={"inline"}/>
                               </IconButton>
                               <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                           size={"small"}
                                           onClick={() => e.component.cancelEditData()}
                               >
                                   <UndoIcon fontSize="small"/>
                               </IconButton>
                           </>
                       )
               }
           </div>
        );
    };

    formatNumber = (e) => {
        if(!e) return false;
        return <NumberFormat value={e.value} displayType={'text'} thousandSeparator={true} />
    };

    loadMCboProject = () => {
        this.props.w77F1013Actions.getMCboProject(error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        })
    };

    componentDidMount() {
        this.loadMCboProject();
    }

    getDataTotal = (e) => {
        const { getTotalAllocation } = this.props;
        getTotalAllocation && getTotalAllocation(e.component.getTotalSummaryValue('Amount'))
    };

    render() {
        const {gridRef, dataCostAllocation, getMCboProject} = this.props;
        return (
            <div className={"mgb25"} style={{width:'100%'}}>
                <GridContainer
                    reference={ref => {
                        gridRef && gridRef(ref);
                        this.dataGrid = ref;
                    }}
                    columnAutoWidth={true}
                    dataSource={dataCostAllocation}
                    elementAttr={{
                        style: "min-height: 100px"
                    }}
                    style={{width: "100%"}}
                    editing={{
                        mode: 'cell',
                        refreshMode: "reshape",
                        allowUpdating: true,
                        texts: {
                            confirmDeleteMessage: ""
                        },
                    }}
                    // filterRow={{
                    //     visible: true,
                    //     showOperationChooser: false,
                    // }}
                    allowColumnResizing={true}
                    hoverStateEnabled={true}
                    typeShort={true}
                    // rowPrepared={this.getDataTotal}
                    onRowInserting={this.getDataTotal}
                    // onRowUpdating={this.getDataTotal}
                    onRowUpdated={this.getDataTotal}

                >
                    <Column
                        allowEditing={false}
                        visible={Config.isMobile}
                        width={110}
                        alignment={"center"}
                        cellRender={this.renderButtons}
                    />
                    <Column
                    caption={Config.lang("DHR_Loai_phan_bo")}
                    dataField={'ProjectID'}
                    alignment={"left"}
                    width={250}
                    lookup={{
                        dataSource: getMCboProject,
                        valueExpr: "ProjectID",
                        displayExpr: "ProjectName",
                    }}
                    editorOptions={{ placeholder: Config.lang("DHR_Loai_phan_bo") + "...",
                    }}
                    />

                    <Column
                        dataField="Amount"
                        alignment={"right"}
                        caption={Config.lang("DHR_So_tien")}
                        dataType="number"
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        cellRender={this.formatNumber}
                    />
                    <Column
                        alignment={"center"}
                        caption={Config.lang("DHR_Ghi_chu")}
                        dataField={'Note'}
                    />
                    <Column
                        allowEditing={false}
                        visible={!Config.isMobile}
                        fixed={true}
                        fixedPosition={"right"}
                        cellRender={this.renderButtons}
                    />
                    <Summary recalculateWhileEditing={true}>
                        <TotalItem
                            column="RecCostID"
                            displayFormat={Config.lang("DHR_Tong")}
                        />
                        <TotalItem
                            column="Amount"
                            summaryType="sum"
                            valueFormat={"fixedPoint"}
                            displayFormat={'{0}'}
                        />
                    </Summary>
                </GridContainer>
            </div>
        );
    }
}
W77F1013TableAllocation.propTypes = {
    gridRef: PropTypes.func
};


export default compose(
    connect(
        state => ({
            getMCboProject: state.W77F1013.getMCboProject,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w77F1013Actions: bindActionCreators(W77F1013Actions, dispatch)
        })
    ))(W77F1013TableAllocation);