import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import LabelText from "../../../common/label-text/label-text";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import Approvals from "../../../approvals/approvals";

import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from "lodash";
import AnimateHeight from "react-animate-height";
import GridContainer from "../../../grid-container/grid-container";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
    },
    divText: {
        fontWeight: 500,
    },
    gridTable: {
        "& .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort, .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator, .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content": {
            color: "#B2BABB !important",
        },
        "& .dx-datagrid-content":{
            maxHeight: "calc(30px * 5)",
            overflow: "scroll"
        }
    },
};

class W77F1014 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataForm: {},
            VoucherID: "",
            toggleTable1: true,
            toggleTable2: true,
            toggleTable3: true,
        };
        this.defaultParams = {
            FormID: "W77F1014",
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
        };
    }

    loadFormInfo = (VoucherID) => {
        const params = {
            ...this.defaultParams,
            VoucherID,
        };
        this.setState({ loading: true });
        this.props.approvalActions.getFormInfo(params, (err, data) => {
            this.setState({ loading: false });
            if (err) return Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
            if (data) {
                this.setState({ dataForm: data });
            }
        });
    };

    onDrawerClosed = () => {
        this.setState({ VoucherID: "" });
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(_.get(e, "VoucherID", ""));
    };

    formatDate = (date, typeFormat = "DD/MM/YYYY") => {
        if (!date) return "";
        if (moment(date).isValid()) {
            return moment.utc(date).format(typeFormat);
        }
        return "";
    };

    formatCurrency = (value, currency = "USD") => {
        if (value === "") return "";
        let valueParsed = parseFloat(value);
        let languageCode = "en-US";
        let regexSeparator = /\,/g; //eslint-disable-line
        let charReplace = ".";
        let options = valueParsed % 1 === 0 ? { minimumFractionDigits: 0, maximumFractionDigits: 0 } : {};
        if (currency === "VND") {
            languageCode = "vi-VN";
            regexSeparator = /\./g; //eslint-disable-line
            charReplace = ",";
            valueParsed = value;
        }

        const formatter = new Intl.NumberFormat(languageCode, {
            style: "currency",
            currency,
            ...options,
        });

        return formatter.format(valueParsed).replace(regexSeparator, charReplace);
    };

    renderFormInfo = (data) => {
        const { classes } = this.props;
        const { toggleTable1, toggleTable2, toggleTable3 } = this.state;
        const userData = Config.getUser({ EmployeeID: _.get(data, "EmployeeID", "") });
        let useCurency = _.clamp(_.get(data, "UseCurency[0].UseCurency", 2), 1, 2);
        useCurency = _.isNumber(Number(useCurency)) ? useCurency : 1;
        const isUseCostAllocation = _.get(data, "UseCurency[0].IsUseCostAllocation", 1);
        const objDataField = {
            0: [
                {
                    label: Config.lang("DHR_Ma_cong_tac"),
                    value: _.get(data, "BusinessTravelCode", ""),
                },
                {
                    label: Config.lang("DHR_Ngay_di_/_ve"),
                    value: _.join(
                        _.compact([this.formatDate(_.get(data, "FromDate", "")), this.formatDate(_.get(data, "ToDate", ""))]),
                        " - ",
                    ),
                },
            ],
            1: [
                {
                    label: Config.lang("DHR_Dia_diem"),
                    value: _.join(_.compact([_.get(data, "IsDomesticName", ""), _.get(data, "BusinessLocation", "")]), " - "),
                },
            ],
            2: [
                {
                    label: Config.lang("DHR_Muc_dich_cong_tac"),
                    value: _.get(data, "Content", ""),
                },
            ],
            3: [
                {
                    label: Config.lang("DHR_So_tien_de_xuat_tam_ung"),
                    value: _.join(
                        _.compact([
                            this.formatCurrency(_.get(data, "ProAmountVND", ""), "VND"),
                            useCurency > 1 ? this.formatCurrency(_.get(data, "ProAmountUSD", "")) : false,
                        ]),
                        " ~ ",
                    ),
                },
                {
                    label: Config.lang("DHR_Ngay_de_xuat_tam_ung"),
                    value: this.formatDate(_.get(data, "ProDateAdvance", "")),
                },
            ],
            4: [
                {
                    label: Config.lang("DHR_Ly_do_tam_ung"),
                    value: _.get(data, "Reason", ""),
                },
            ],
        };
        const dataTable1 = _.get(data, "RecCost1", []);
        const dataTable2 = _.get(data, "RecCost2", []);
        const dataTable3 = _.get(data, "Allocation", []);
        const dataTable4 = [
            {
                CostTypeName: Config.lang("DHR_Tong_chi_phi_thuc_te_chuyen_di"),
                CostValueVND: this.formatCurrency(_.get(data, "TotalAmountVND", 0), "VND"),
                CostValueUSD: this.formatCurrency(_.get(data, "TotalAmountUSD", 0)),
            },
            {
                CostTypeName: Config.lang("DHR_Tong_chi_phi_nhan_vien_su_dung"),
                CostValueVND: this.formatCurrency(_.get(data, "EmployeePayVND", 0), "VND"),
                CostValueUSD: this.formatCurrency(_.get(data, "EmployeePayUSD", 0)),
            },
            {
                CostTypeName: Config.lang("DHR_Tong_chi_phi_cong_ty_thanh_toan"),
                CostValueVND: this.formatCurrency(_.get(data, "CompanyPayVND", 0), "VND"),
                CostValueUSD: this.formatCurrency(_.get(data, "CompanyPayUSD", 0)),
            },
            {
                CostTypeName: Config.lang("DHR_Chi_phi_tam_ung"),
                CostValueVND: this.formatCurrency(_.get(data, "ProAmountVND", 0), "VND"),
                CostValueUSD: this.formatCurrency(_.get(data, "ProAmountUSD", 0)),
            },
            {
                CostTypeName: Config.lang("DHR_Chi_con_lai_can_quyet_toan"),
                CostValueVND: this.formatCurrency(_.get(data, "ReimburseVND", 0), "VND"),
                CostValueUSD: this.formatCurrency(_.get(data, "ReimburseUSD", 0)),
            },
        ];
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <LabelText
                                className={classes.labelEmployee}
                                fullWidth={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                allowPadding={true}
                                value={() => (
                                    <div className={"display_row align-center pdb5"} style={{ width: "100%", height: 37 }}>
                                        <UserImage data={data || userData} width={32} height={32} />
                                        <UserName data={data || userData} allowHover={false} />
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                {_.map(_.keysIn(objDataField), (key) => (
                    <Row key={key}>
                        {_.map(objDataField[key], (item, i) => (
                            <Col key={i} md={12 / objDataField[key].length} xs={12} className={"mgb15"}>
                                <LabelText InputProps={{ readOnly: true }} label={item.label} value={item.value} fullWidth={true} />
                            </Col>
                        ))}
                    </Row>
                ))}
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <label
                                className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                                style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: 600 }}
                                onClick={() => {
                                    this.setState({ toggleTable1: !toggleTable1 });
                                }}
                            >
                                <i
                                    style={{
                                        transform: toggleTable1 ? "rotate(0)" : "rotate(180deg)",
                                        transition: "0.4s",
                                    }}
                                    className={"fas fa-caret-down mgr5"}
                                />
                                {Config.lang("DHR_Khoan_chi_phi_du_kien")}
                            </label>
                            <AnimateHeight
                                duration={500}
                                height={toggleTable1 ? "auto" : 0} // see props documentation below
                            >
                                <GridContainer
                                    loading={false}
                                    gridProps={{
                                        className: `grid_luc ${classes.gridTable}`,
                                    }}
                                    style={{ border: "none" }}
                                    dataSource={dataTable1}
                                    showRowLines={false}
                                    showBorders={false}
                                    showColumnLines={false}
                                    noDataText={Config.lang("DHR_No_data")}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                >
                                    <Column caption={Config.lang("DHR_Khoan_chi")} dataField={"RecCostName"} />
                                    <Column alignment="center" caption={Config.lang("DHR_Thanh_tien(VND)")} dataField={"AmountVND"} cellRender={e => this.formatCurrency(_.get(e,"row.data.AmountVND", ""),"VND")} />
                                    {useCurency === 2 ? (
                                        <Column
                                            alignment="center"
                                            caption={Config.lang("DHR_Thanh_tien(USD)")}
                                            dataField={"AmountUSD"}
                                            cellRender={e => this.formatCurrency(_.get(e,"row.data.AmountUSD", ""),"USD")}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <Column
                                        dataType="boolean"
                                        caption={Config.lang("DHR_Cong_ty_thanh_toan")}
                                        dataField={"IsCompanyPay"}
                                        alignment={"center"}
                                    />
                                    <Column caption={Config.lang("DHR_Ghi_chu")} dataField={"Note"} alignment={"left"} />
                                    <Summary>
                                        <TotalItem column="RecCostName" customizeText={() => Config.lang("DHR_Tong_cong") + ": "} />
                                        <TotalItem
                                            column="AmountVND"
                                            summaryType="sum"
                                            customizeText={(data) => this.formatCurrency(_.get(data, "value", ""), "VND")}
                                        />
                                        {useCurency === 2 ? (
                                            <TotalItem
                                                column="AmountUSD"
                                                summaryType="sum"
                                                customizeText={(data) => this.formatCurrency(_.get(data, "value", ""),"USD")}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Summary>
                                </GridContainer>
                            </AnimateHeight>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <label
                                className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                                style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: 600 }}
                                onClick={() => {
                                    this.setState({ toggleTable2: !toggleTable2 });
                                }}
                            >
                                <i
                                    style={{
                                        transform: toggleTable2 ? "rotate(0)" : "rotate(180deg)",
                                        transition: "0.4s",
                                    }}
                                    className={"fas fa-caret-down mgr5"}
                                />
                                {Config.lang("DHR_Khoan_chi_de_nghi_quyet_toan")}
                            </label>
                            <AnimateHeight
                                duration={500}
                                height={toggleTable2 ? "auto" : 0} // see props documentation below
                            >
                                <GridContainer
                                    loading={false}
                                    gridProps={{
                                        className: `grid_luc ${classes.gridTable}`,
                                    }}
                                    style={{ border: "none" }}
                                    dataSource={dataTable2}
                                    showRowLines={false}
                                    showBorders={false}
                                    showColumnLines={false}
                                    noDataText={Config.lang("DHR_No_data")}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                >
                                    <Column caption={Config.lang("DHR_Khoan_chi")} dataField={"RecCostName"} />
                                    <Column alignment="center" caption={Config.lang("DHR_Thanh_tien(VND)")} dataField={"AmountVND"} cellRender={e => this.formatCurrency(_.get(e,"row.data.AmountVND"),"VND")} />
                                    {useCurency === 2 ? (
                                        <Column
                                            alignment="center"
                                            caption={Config.lang("DHR_Thanh_tien(USD)")}
                                            dataField={"AmountUSD"}
                                            cellRender={e => this.formatCurrency(_.get(e,"row.data.AmountUSD"),"USD")}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <Column
                                        dataType="boolean"
                                        caption={Config.lang("DHR_Cong_ty_thanh_toan")}
                                        dataField={"IsCompanyPay"}
                                        alignment={"center"}
                                    />
                                    <Column caption={Config.lang("DHR_Ghi_chu")} dataField={"Note"} alignment={"left"} />
                                    <Summary>
                                        <TotalItem column="RecCostName" customizeText={() => Config.lang("DHR_Tong_cong") + ": "} />
                                        <TotalItem
                                            column="AmountVND"
                                            summaryType="sum"
                                            customizeText={(data) => this.formatCurrency(_.get(data, "value", ""), "VND")}
                                        />
                                        {useCurency === 2 ? (
                                            <TotalItem
                                                column="AmountUSD"
                                                summaryType="sum"
                                                customizeText={(data) => this.formatCurrency(_.get(data, "value", ""), "USD")}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Summary>
                                </GridContainer>
                            </AnimateHeight>
                        </Col>
                    </Row>
                </FormGroup>
                {isUseCostAllocation ? (
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <label
                                    className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                                    style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: 600 }}
                                    onClick={() => {
                                        this.setState({ toggleTable3: !toggleTable3 });
                                    }}
                                >
                                    <i
                                        style={{
                                            transform: toggleTable3 ? "rotate(0)" : "rotate(180deg)",
                                            transition: "0.4s",
                                        }}
                                        className={"fas fa-caret-down mgr5"}
                                    />
                                    {Config.lang("DHR_Phan_bo_chi_phi")}
                                </label>
                                <AnimateHeight
                                    duration={500}
                                    height={toggleTable3 ? "auto" : 0} // see props documentation below
                                >
                                    <GridContainer
                                        loading={false}
                                        gridProps={{
                                            className: `grid_luc ${classes.gridTable}`,
                                        }}
                                        style={{ border: "none" }}
                                        dataSource={dataTable3}
                                        showRowLines={false}
                                        showBorders={false}
                                        showColumnLines={false}
                                        noDataText={Config.lang("DHR_No_data")}
                                        allowColumnResizing={true}
                                        columnResizingMode={"widget"}
                                    >
                                        <Column caption={Config.lang("DHR_Loai_phan_bo")} dataField={"AllocationID"} />
                                        <Column caption={Config.lang("DHR_So_tien")} dataField={"Amount"} />
                                        <Column caption={Config.lang("DHR_Ghi_chu")} dataField={"Note"} />
                                        <Summary>
                                            <TotalItem column="AllocationID" customizeText={() => Config.lang("DHR_Tong_cong") + ": "} />
                                            <TotalItem
                                                column="Amount"
                                                summaryType="sum"
                                                customizeText={(data) => _.get(data, "value", "")}
                                            />
                                        </Summary>
                                    </GridContainer>
                                </AnimateHeight>
                            </Col>
                        </Row>
                    </FormGroup>
                ) : (
                    ""
                )}
                <FormGroup>
                    <Row>
                        <Col md={5}>
                            <label
                                style={{ fontSize: "1.2rem", fontWeight: 600 }}
                                className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                            >
                                {Config.lang("DHR_Tong_hop_chi_phi")}
                            </label>
                        </Col>
                    </Row>
                    {_.map(dataTable4, (item, i) => (
                        <FormGroup key={i}>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs={12 / (useCurency + 1)}>
                                    <label className={"label-above-admin MuiFormLabel-root"}>{`(${i+1}) ${item.CostTypeName}`}</label>
                                </Col>
                                <Col style={{ textAlign: "right" }} md={12 / (useCurency + 1)} xs={3}>
                                    <label className={"label-above-admin MuiFormLabel-root"}>{item.CostValueVND}</label>
                                </Col>
                                {useCurency === 2 ? (
                                    <Col style={{ textAlign: "right" }} md={4} xs={3}>
                                        <label className={"label-above-admin MuiFormLabel-root"}>{item.CostValueUSD}</label>
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>
                        </FormGroup>
                    ))}
                         <Row>
                            <Col md={5}>
                                <label
                                    style={{ fontSize: 13, color:"grey", fontStyle: "italic"}}
                                >
                                    {Config.lang("DHR_Ghi_chu")} : 
                                </label>
                            </Col>
                         </Row>
                         <Row>
                            <Col xs={12}><label style={{fontSize: 12, color:"grey", fontStyle: "italic" }}>(1) = (2) + (3)</label></Col>
                            <Col xs={12}><label style={{fontSize: 12, color:"grey", fontStyle: "italic" }}>(5) = (1) - (3) - (4)</label></Col>
                        </Row>
                </FormGroup>
            </>
        );
    };

    render() {
        const { loading, dataForm, VoucherID } = this.state;
        return (
            <>
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Duyet_quyet_toan_chi_phi_cong_tac")} />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Approvals
                                parentProps={this.props}
                                FormID={"W77F1014"}
                                disabled={loading}
                                ref={"Approvals"}
                                dataForm={dataForm}
                                selectedRowKey={VoucherID}
                                singleClick
                                formInfoRender={(data) => this.renderFormInfo(data)}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }
}

export default compose(
    connect(
        (state) => ({
            getGridApproval: state.approvals.getGridApproval,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W77F1014);
